<template>
  <form
    class="row header-toolbar"
    @submit.prevent="searchClicked()">
    <v-col>
      <v-select
        v-model="filterFactory"
        :items="selectOptions.factories"
        :menu-props="{ offsetY: true }"
        label="Factory"
        item-text="name"
        item-value="value"
        outlined
        hide-details
        dense />
    </v-col>
    <v-col>
      <v-select
        v-model="filterDesigner"
        :items="selectOptions.designers"
        :menu-props="{ offsetY: true }"
        label="Designer"
        item-text="name"
        item-value="value"
        outlined
        hide-details
        dense />
    </v-col>
    <v-col>
      <v-select
        v-model="filterMerchandiser"
        :items="selectOptions.merchandisers"
        :menu-props="{ offsetY: true }"
        label="Merchandiser"
        item-text="name"
        item-value="value"
        outlined
        hide-details
        dense />
    </v-col>
    <v-col cols="2">
      <v-combobox
        v-model.trim="filterCollection"
        :items="selectOptions.collections"
        item-text="name"
        item-value="value"
        label="Collection"
        outlined
        hide-details
        dense />
    </v-col>
    <v-col>
      <v-select
        v-model="filterItemsPerPage"
        :items="selectOptions.itemsPerPage"
        item-text="text"
        item-value="value"
        :menu-props="{ offsetY: true }"
        label="Item Per Page"
        outlined
        hide-details
        dense />
    </v-col>
    <v-spacer />
    <v-col
      md="5"
      sm="12">
      <div class="d-flex justify-end">
        <v-btn
          class="mx-4 create-sketch-btn"
          color="success"
          to="/prototype/sketch/create">
          CREATE
        </v-btn>
        <search-box
          v-model="filterSearch"
          @on-search="searchClicked()" />
      </div>
    </v-col>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import MerchandiserProvider from '@/resources/MerchandiserProvider'
import DesignerProvider from '@/resources/DesignerProvider'
import FactoryProvider from '@/resources/FactoryProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchBox from '@/components/SearchBox.vue'

const MerchandiserService = new MerchandiserProvider()
const DesignerService = new DesignerProvider()
const FactoryService = new FactoryProvider()
const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: { SearchBox },
  props: {
    state: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectOptions: {
        designers: [{ name: 'ALL', value: '' }],
        factories: [{ name: 'ALL', value: '' }],
        merchandisers: [{ name: 'ALL', value: '' }],
        collections: [],
        itemsPerPage: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
          { value: 20, text: '20' },
          { value: 25, text: '25' },
          { value: 999, text: 'All' }
        ]
      },
      delayCollectionSearch: null
    }
  },
  computed: {
    filterFactory: {
      get () {
        return this.value?.factory || ''
      },
      set (val) {
        this.$emit('input', {
          ...this.value,
          factory: val
        })
      }
    },
    filterSearch: {
      get () {
        return this.value?.search || ''
      },
      set (val) {
        this.value.search = val
      }
    },
    filterDesigner: {
      get () {
        return this.value?.designer || ''
      },
      set (val) {
        this.$emit('input', {
          ...this.value,
          designer: val
        })
      }
    },
    filterMerchandiser: {
      get () {
        return this.value?.merchandiser || ''
      },
      set (val) {
        this.$emit('input', {
          ...this.value,
          merchandiser: val
        })
      }
    },
    filterItemsPerPage: {
      get () {
        return this.value?.itemsPerPage || ''
      },
      set (val) {
        this.$emit('input', {
          ...this.value,
          itemsPerPage: val
        })
      }
    },
    filterCollection: {
      get () {
        return this.value?.collection || ''
      },
      set (val) {
        clearTimeout(this.delayCollectionSearch)
        this.delayCollectionSearch = setTimeout(() => {
          this.$emit('input', {
            ...this.value,
            collection: val?.value || val
          })
        }, 1000)
      }
    }
  },
  mounted () {
    this.initSelectOptions()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    searchClicked () {
      this.$emit('input', { ...this.value, state: this.state })
    },
    async initSelectOptions () {
      try {
        this.setLoading({
          active: true,
          clickAble: false
        })

        const promised = await Promise.all([
          MerchandiserService.getAll(),
          DesignerService.getAll(),
          FactoryService.getAll(),
          ProductAttributeService.getAllProductAttribute()
        ])

        this.selectOptions.merchandisers = promised[0].data.results.map((item) => ({ id: item.id, name: item.name, value: item.name }))
        this.selectOptions.designers = promised[1].data.results.map((item) => ({ id: item.id, name: item.name, value: item.name }))
        this.selectOptions.factories = promised[2].data.results.map((item) => ({ id: item.id, name: item.name, value: item.name }))
        this.selectOptions.collections = promised[3].data.collections
          ? promised[3].data.collections.map((item) => ({ id: item.id, name: item.name, value: item.name }))
          : []

        this.selectOptions.merchandisers = [{ name: 'ALL', value: '' }, { divider: true }, ...this.selectOptions.merchandisers]
        this.selectOptions.designers = [{ name: 'ALL', value: '' }, { divider: true }, ...this.selectOptions.designers]
        this.selectOptions.factories = [{ name: 'ALL', value: '' }, { divider: true }, ...this.selectOptions.factories]
      } catch (error) {
        console.error('initSelectOptions', error)
        this.setSnackbar({
          value: true,
          message: `ERROR WHILE INIT OPTIONS: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style lang="scss">
.header-toolbar {
  position: sticky;
  top: 50px;
  z-index: 5;
  background-color: #ffffff;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
}

@media only screen and (max-width: 767px) {
  .create-sketch-btn {
    max-width: 50px;
    content: '•';
  }
}
</style>
