import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SketchesProvider extends HttpRequest {
  constructor () {
    super(`${process.env.VUE_APP_MER_API}/prototypes`)
  }

  getManySketches (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/sketch', query)
  }

  getOneSketchById (sketchId) {
    this.setHeader(getAuthToken())
    return this.get(`/sketch/${sketchId}`)
  }

  getSketchBySketchId (sketchId) {
    this.setHeader(getAuthToken())
    return this.get(`/sketch/${sketchId}/sketch-id`)
  }

  createSketch (sketchForm) {
    this.setHeader(getAuthToken())
    return this.post('/sketch', sketchForm)
  }

  updateSketch (sketchId, sketchForm) {
    this.setHeader(getAuthToken())
    return this.put(`/sketch/${sketchId}`, sketchForm)
  }

  deleteSketch (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/${id}`)
  }
}

export default SketchesProvider
